/** @jsx jsx */
import { jsx } from 'theme-ui'

import { Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Container, Grid, Text } from 'gatsby-theme-landmarks-core'
var d = new Date();
var n = d.getFullYear();

const Footer = () => {
  const { facebook, insta, youtube } = useStaticQuery(graphql`
    {
      facebook: file(name: { eq: "facebook" }) {
        childImageSharp {
          fixed(height: 40, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      insta: file(name: { eq: "insta" }) {
        childImageSharp {
          fixed(height: 40, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      youtube: file(name: { eq: "youtube" }) {
        childImageSharp {
          fixed(height: 36, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{
        backgroundColor: 'primary',
        color: 'secondary'
      }}
    >
      <Container
        sx={{
          position: 'relative',
          pl: 2,
          pr: 2
        }}
      >
        <Grid gap={4} columns={[1, null, 3]}>
          <div
            sx={{
              height: '100%',
              px: 4,
              pt: 4
            }}
          >
            <Text variant="small">
              <b>SCALA SKYLINE SRL</b> - ONRC: J12/694/23.03.2009, CUI: RO25333191, str. Branului, nr.53 Cluj-Napoca.
            </Text>
          </div>
          <div>
            <div
              sx={{
                pb: 4,
                alignContent: 'center'
              }}
            >
              <ul sx={{
                display: 'inline-flex',
                listStyle: 'none',
                alignContent: 'center',
                alignItems: 'center',
              }}>

                <li sx={{
                  px: 3
                }}>
                  <a href="https://www.facebook.com/ScalaDevelopment.ro"
                    target="_blank">
                    <Img fixed={facebook.childImageSharp.fixed} alt="Facebook" />
                  </a>
                </li>

                <li sx={{
                  px: 3
                }}>
                  <a href="https://www.instagram.com/scaladevelopment.ro/"
                    target="_blank">
                    <Img fixed={insta.childImageSharp.fixed} alt="Instagram" />
                  </a>
                </li>
                <li sx={{
                  px: 3
                }}>
                  <a href="https://www.youtube.com/channel/UC7OpMQ7TrwZSNDPVi6Kn-Rg"
                    target="_blank">
                    <Img fixed={youtube.childImageSharp.fixed} alt="Youtube" />
                  </a>
                </li>
              </ul>
            </div>
            <Text variant="small" sx={{
              pt: 5,
              px: 4
            }}>
              ©{n} Scala Development | developed by
              <a href="https://landmarks.ro/" sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: 12
              }} > Landmarks</a>
            </Text>
          </div>
          <div sx={{
          }}>
            <ul sx={{
              listStyle: 'none'
            }}>
              <li>
                <Link sx={{ color: 'rgba(255, 255, 255, .5)' }} to="/apartamente-noi">
                  Apartamente
                </Link>
              </li>
              <li>
                <Link sx={{ color: 'rgba(255, 255, 255, .5)' }} to="/politica-confidentialitate-gdpr">
                  Protecţia Datelor cu Caracter Personal
                </Link>
              </li>
              <li>
                <a
                  href="https://anpc.ro/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'rgba(255, 255, 255, .5)' }}
                >
                  ANPC
                </a>
              </li>
              <li>
                <a
                  href="https://scalacenter.ro/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'rgba(255, 255, 255, .5)' }}
                >
                  Scala Center
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
