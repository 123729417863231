/** @jsx jsx */
import { jsx, Button } from "theme-ui"

import React, { useContext } from "react"
import { Link, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'

import {
  GlobalDispatchContext,
  GlobalStateContext,
  A,
  Container,
  usePortal,
  ScrollLock,
  globalActions,
  useWindowSize,
} from "gatsby-theme-landmarks-core";

import ClicktoCall from "../../components/ClicktoCall/ClicktoCall";

const TRACKING_FLAG = "allowTracking";

const IconClose = (props) => (
  <div {...props}>
    <svg
      sx={{ width: "45px", height: "45px", color: "primary" }}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
      />
    </svg>
  </div>
);

const Logo = (props) => (
  <Link className={props.className} to="/" sx={{ display: "block", maxWidth: ["240px", "270px"] }}>
    <Img fluid={props.logo} alt={props.alt} />
  </Link>
);

const UpTop = (props) => (
  <div
    sx={{
      cursor: "pointer",
      position: "absolute",
      right: 0,
      top: 0,
      display: 'flex',
    }}
    {...props}
  />
);

const Header = (props) => {
  const { logoAlt, children } = props;
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { openPortal, closePortal, isOpen, Portal } = usePortal();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;

  const { logo } = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "Scala-Center-horizontal" }) {
      childImageSharp {
        fluid(maxHeight: 80 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
          presentationHeight
        }
      }
    }
    }
  `)

  return (
    <Container
      as="header"
      sx={{
        background: "#fff",
        position: ["sticky", "sticky", "sticky"],
        maxWidth: "1920px",
        top: 0,
        zIndex: 3,
        pl: [2, 4],
        pr: [0, 4],
        display: "flex",
        alignItems: "center",
        fontFamily: "Arial",
        justifyContent: "space-between",
      }}
    >
      {isMobile ? (
        <>
          <Logo logo={logo.childImageSharp.fluid} alt={logoAlt} sx={{
            flex: 1,
            maxWidth: ['150px']
          }} />

          <div
            sx={{
              fontSize: "14px",
              display: 'flex',
            }}
          >
            <Button
              sx={{
                display: ["block", "none"],
                mt: 1,
                py: 1,
                px: 3,
                minWidth: 'auto',
                my: 2,
              }}
            >
              <ClicktoCall title={isMobile ? 'SUNA' : 'SUNA ACUM'}>
                <Portal>
                  <ScrollLock>
                    <div
                      sx={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: "white",
                        zIndex: 3,
                      }}
                    >
                      <Container
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Logo
                          logo={logo.childImageSharp.fluid}
                          alt={logoAlt}
                          sx={{ left: [3, 4] }}
                        />
                        <UpTop>
                          <IconClose
                            onClick={closePortal}
                            sx={{
                              float: "right",
                              p: [3, 4],
                              "&:hover": {
                                color: "primary",
                              },
                            }}
                          />
                        </UpTop>
                        <div
                          sx={{
                            pt: "100px",
                            pl: [3, null, 7],
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a
                            href="tel:0756 056 000"
                            sx={{
                              variant: "links.inYourFace",
                              color: "primary",
                              pl: 0,
                            }}
                          >
                            0756 056 000
                          </a>
                          <a
                            href="tel:0754 079 944"
                            sx={{
                              variant: "links.inYourFace",
                              color: "primary",
                              pl: 0,
                            }}
                          >
                            0754 079 944
                          </a>
                        </div>
                      </Container>
                    </div>
                  </ScrollLock>
                </Portal>
              </ClicktoCall>
            </Button>
            <div
              onClick={openPortal}
              sx={{
                width: '50px',
                px: 2,
                display: 'flex',
                "&:hover": {
                  color: "secondary",
                },
              }}>
              <svg
                sx={{ color: "Gray", height: '54px' }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <>
          <Logo logo={logo.childImageSharp.fluid} alt={logoAlt} sx={{ flex: 1 }} />
          <div sx={{
            display: 'flex',
            a: {
              px: 3,
              py: 2,
              textDecoration: "none",
              ":hover": {},
            },
          }}>
            <A to="/apartamente-noi/etaj-RETRAS">APARTAMENTE DISPONIBILE</A>
            {/* <A to="/apartamente-noi">
                        Modele apartamente
                </A> */}
            <A to="/despre">DESPRE PROIECT</A>
            <A to="/contact">CONTACT</A>
            <Button
              variant="primary"
              style={{
                minWidth: "auto",
                width: "200px",
                margin: 0,
                padding: "4px",
              }}
            >
              <ClicktoCall title="SUNA ACUM">
                <div sx={{ fontSize: 1 }}>
                  0754 079 944 / 0756 056 000
                </div>
              </ClicktoCall>
            </Button>
          </div>
        </>
      )}
      {children}

      {state.showGDPR && (
        <Portal>
          <div
            sx={{
              backgroundColor: "lightGrayOpaque",
              py: 3,
              px: 4,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 3,
            }}
          >
            <span>
              Acest site folosește cookies pentru a asigura cea mai bună
              experiență de utilizare.{" "}
              <Link
                sx={{ variant: "links.default" }}
                to="/politica-confidentialitate-gdpr"
              >
                Afla mai multe
              </Link>
            </span>
            <div
              sx={{
                position: [null, null, "absolute"],
                right: 0,
                top: 2,

                button: {
                  mb: 0,
                  mr: 3,
                  minWidth: "120px",
                },
              }}
            >
              <Button
                variant="primary"
                onClick={() => {
                  sessionStorage.setItem(TRACKING_FLAG, true);
                  dispatch({
                    type: globalActions.TOGGLE_SHOW_GDPR,
                  });
                }}
              >
                Accept
              </Button>
              <Button
                variant="clear"
                onClick={() =>
                  dispatch({
                    type: globalActions.TOGGLE_SHOW_GDPR,
                  })
                }
              >
                Mai tarziu
              </Button>
            </div>
          </div>
        </Portal>
      )}

      {isOpen && (
        <Portal>
          <ScrollLock>
            <div
              sx={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: "white",
                zIndex: 3,
              }}
            >
              <Container
                sx={{
                  position: "relative",
                }}
              >
                <Logo logo={logo.childImageSharp.fluid} alt={logoAlt} sx={{ left: [3, 4] }} />
                <UpTop>
                  <IconClose
                    onClick={closePortal}
                    sx={{
                      float: "right",
                      p: [3, 4],
                      "&:hover": {
                        color: "primary",
                      },
                    }}
                  />
                </UpTop>
                <div
                  sx={{
                    pt: "100px",
                    pl: [null, null, 7],
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    sx={{
                      variant: "links.inYourFace",
                      color: "primary",
                    }}
                  >
                    <ClicktoCall title="SUNA ACUM">
                      <a
                        href="tel:0756 056 000"
                        sx={{
                          variant: "links.inYourFace",
                          color: "primary",
                          pl: 0,
                        }}
                      >
                        0756 056 000
                      </a>
                      <br></br>
                      <a
                        href="tel:0754 079 944"
                        sx={{
                          variant: "links.inYourFace",
                          color: "primary",
                          pl: 0,
                        }}
                      >
                        0754 079 944
                      </a>
                    </ClicktoCall>
                  </div>
                  <A
                    sx={{ variant: "links.inYourFace" }}
                    to="/apartamente-noi/etaj-RETRAS"
                  >
                    Apartamente disponibile
                  </A>
                  {/* <A
                      sx={{ variant: 'links.inYourFace' }}
                      to="/apartamente-noi"
                    >
                      Modele apartamente
                    </A> */}

                  <A sx={{ variant: "links.inYourFace" }} to="/despre">
                    Despre Proiect
                  </A>
                  <A
                    sx={{ variant: "links.inYourFace", color: "primary" }}
                    to="/contact"
                  >
                    Contact
                  </A>
                </div>
              </Container>
            </div>
          </ScrollLock>
        </Portal>
      )}
    </Container>
  );
};

export default Header;
