/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, Button } from 'theme-ui'
import { Link } from 'gatsby'

export const ClicktoCall = props => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? props.children : props.title}      
        </div>
    )
}
export default ClicktoCall